import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useUtmSessionStorage() {
	const router = useRouter();

	useEffect(() => {
		if (!sessionStorage || !('URLSearchParams' in window)) {
			return;
		}

		const searchParams = new URLSearchParams(window.location.search);
		let redirect = false;

		if (searchParams.get('utm_source')) {
			sessionStorage.setItem('utm_source', searchParams.get('utm_source'));
			redirect = true;
		}
		if (searchParams.get('utm_medium')) {
			sessionStorage.setItem('utm_medium', searchParams.get('utm_medium'));
			redirect = true;
		}
		if (searchParams.get('utm_content')) {
			sessionStorage.setItem('utm_content', searchParams.get('utm_content'));
			redirect = true;
		}
		if (searchParams.get('utm_campaign')) {
			sessionStorage.setItem('utm_campaign', searchParams.get('utm_campaign'));
			redirect = true;
		}
		if (searchParams.get('utm_term')) {
			sessionStorage.setItem('utm_term', searchParams.get('utm_term'));
			redirect = true;
		}
		if (searchParams.get('environment')) {
			sessionStorage.setItem('environment', searchParams.get('environment'));
			redirect = true;
		}

		if (redirect) {
			router.push(router.asPath.split('?')[0], undefined, { shallow: true });
		}
	}, [router]);
}
